.list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.65rem;
    margin: 0.25rem 0;
    border: 1px solid #ececec;
    background-color: #001e5f;
    color: white;
    border-radius: 5px;
    box-shadow: 0 1px 8px rgb(0 0 0 / 25%);
}

.list-item__data {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-flow: column-reverse;
    justify-content: flex-start;
    flex: 1;
}

.list-item h2 {
    color: #3a3a3a;
    font-size: 1rem;
    flex: 1;
    margin: 0 1rem;
    color: white;
}

.list-item button {
    font: inherit;
    border: 1px solid #2196f3;
    background: #2196f3;
    color: white;
    padding: 0.3rem 1rem;
    margin: 0.05rem 0.35rem;
    cursor: pointer;
    font-size: 0.85rem;
    border-radius: 30px;
}

.list-item button:hover,
.list-item button:active {
    background: #03a9f4;
    border-color: #03a9f4;
}

.list-item button:focus {
    outline: none;
}

.list-item button:disabled,
.list-item button:focus:disabled,
.list-item button:hover:disabled,
.list-item button:active:disabled {
    background: #ccc;
    border-color: #ccc;
    color: #666666;
    cursor: not-allowed;
}

@media (min-width: 580px) {
    .list-item__data {
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }

    .list-item__data h2 {
        font-size: 1.25rem;
    }
}