*,
*:before,
*:after {
  box-sizing: border-box;
  outline: none;
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;

  color: black;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue",
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  background: white;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 0.75em;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
 
::-webkit-scrollbar-thumb {
  background: #001e5f;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #163d92;
}

#root {
  margin: 0 auto;
  height: 100%;
  width: 100%;
}