.counter {
    width: 90%;
    max-width: 50rem;
    margin: auto;
    display: block;
    padding: 0.55rem;
}

.counter__control {
    margin: 1rem 0;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    overflow: hidden;
}

.counter__control label {
    display: block;
    font-weight: bold;
    flex: 1;
    color: #464646;
    margin-bottom: 0.5rem;
}

.counter__board {
    display: flex;
    flex-direction: column;
    width: 5.5rem;
    height: 5.5rem;
    border: 1px solid #ececec;
    background-color: #001e5f;
    color: white;
    margin: 0.45rem 0.75rem;
    border-radius: 12px;
    align-items: center;
    justify-content: center;
    box-shadow: 0 1px 8px rgb(0 0 0 / 25%);
}

.counter__board__header {
    font-size: 0.75rem;
    font-weight: bold;
}

.counter__board__data {
    font-size: 1.5rem;
    font-weight: bold;
}

.counter__board__footer {
    font-size: 0.75rem;
}